import { useTranslation } from "react-i18next";
import { useMatches } from "react-router";

import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";

import {
  BREADCRUMB_FONT_SIZE,
  BREADCRUMB_MAXIMUM_VISIBLE,
} from "utils/constants";

import { RouteHandle } from "routes";

import RouteBreadcrumb from "../routeBreadcrumbs/RouteBreadcrumb";

function NavbarBreadcrumb() {
  const matches = useMatches();
  const { t } = useTranslation();
  const crumbs = matches
    .filter((match) => (match.handle as RouteHandle)?.crumb != null)
    .flatMap((match) => {
      return {
        crumb: (match.handle as RouteHandle).crumb!(t, match.params),
        key: match.id ?? match.pathname,
        match,
      };
    });

  return crumbs.length > 1 ? (
    <Breadcrumbs
      separator={<NavigateNext />}
      maxItems={BREADCRUMB_MAXIMUM_VISIBLE}
      style={{
        fontSize: BREADCRUMB_FONT_SIZE,
        fontWeight: "bold",
        gap: "0.25rem",
      }}
    >
      {crumbs.map(({ crumb: { href, ...crumbProps }, key, match }, i) => (
        <RouteBreadcrumb
          {...crumbProps}
          href={
            i !== crumbs.length - 1
              ? ((href as string | undefined) ?? match.pathname)
              : undefined
          }
          key={key}
        />
      ))}
    </Breadcrumbs>
  ) : null;
}

export default NavbarBreadcrumb;
