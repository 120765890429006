import { PropsWithChildren } from "react";

import { Navigate } from "react-router";

import { ProtectedRouteType } from "./types";

const ProtectedRoute = (props: PropsWithChildren<ProtectedRouteType>) => {
  const { resourceCheckFn, redirectRoute } = props;

  const hasPermission = Array.isArray(props["component-id"])
    ? props["component-id"].some((id) => resourceCheckFn(id))
    : resourceCheckFn(props["component-id"]);

  if (!hasPermission) {
    return <Navigate to={redirectRoute ?? "/"} replace />;
  }

  return props.children;
};
export default ProtectedRoute;
