import { ReactNode } from "react";

import { matchPath } from "react-router";

import { SidebarItemsType } from "types/sidebar";

import SidebarNavList from "./SidebarNavList";
import SidebarNavListItem from "./SidebarNavListItem";

type Arguments = {
  depth: number;
  page: SidebarItemsType;
  items: ReactNode[];
  currentRoute: string;
  resourcePermision: (componentId: string) =>
    | {
        name: string;
        visible: boolean;
        actionable: boolean;
      }
    | undefined;
};

const reduceChildRoutes = ({
  items,
  page,
  depth,
  currentRoute,
  resourcePermision,
}: Arguments) => {
  if (
    page["component-id"] &&
    !resourcePermision(page["component-id"])?.visible
  ) {
    return items;
  }

  if (page.children?.length) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute,
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
      />,
    );
  }

  return items;
};

export default reduceChildRoutes;
