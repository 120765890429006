import { useLocation } from "react-router";

export function useGetDashboardComponentIdPath() {
  const { pathname } = useLocation();
  if (pathname.includes("tenant") && pathname.includes("overview")) {
    return "tenantOverviewPage";
  }

  if (pathname.includes("project") && pathname.includes("overview")) {
    return "projectOverviewPage";
  }

  if (pathname.includes("dashboard")) {
    return "dashboardsPage.dashboardsTable.clickDashboardDetailedPage";
  }

  return "";
}
