import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router";

import { Box } from "@mui/material";

import Loader from "components/Loader";

import ApplicationLayout from "layouts/Application";

import { TenantSelectPage } from "pages/tenantSelector/TenantSelector";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { authenticationApi } from "fetch/authentication";

import { getAccessToken, setJwtData } from "utils/jwt";

import { setupI18n } from "i18n";

const getCodeFromUrl = () => {
  const searchString = document.location.search;
  const pairs = searchString.substring(1).split("&");
  if (pairs.length)
    return pairs.find((param) => param.includes("code="))?.replace("code=", "");
  return "";
};

const LoaderScreen = () => {
  return (
    <Box
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  );
};

export const Root = () => {
  const [clientAuthorized, setClientAuthorized] = useState(false);
  const accessToken = getAccessToken();
  const code = getCodeFromUrl();
  const navigate = useNavigate();
  const storage = useRef(useUserPreferencesStore.getState());

  const getLanguageToUse = () => {
    const i18nCache = localStorage.getItem("i18nextLng");
    const userLocale = storage.current.user?.locale;
    const preferenceLanguage = storage.current.language;

    if (storage.current.isFirstLogin) {
      return [userLocale, preferenceLanguage, i18nCache, "en-GB"].find(
        (lang) => !!lang,
      );
    }

    return [i18nCache, userLocale, preferenceLanguage, "en-GB"].find(
      (lang) => !!lang,
    );
  };

  useEffect(() => {
    const initialize = async () => {
      const accessToken = getAccessToken();
      const code = getCodeFromUrl();

      setClientAuthorized(!!accessToken);
      try {
        if (!accessToken && !code) {
          authenticationApi.authenticateClient();
          setClientAuthorized(true);
        } else setClientAuthorized(true);
      } catch (err) {
        console.error("Error during authentication initialization:", err);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    storage.current = useUserPreferencesStore.getState();
  }, []);

  useEffect(() => {
    const langToUse = getLanguageToUse();

    setupI18n(langToUse ?? "");
  }, [storage.current.user]);

  if (!clientAuthorized) return null;
  if (!accessToken && !code) return <LoaderScreen />;
  if (code) {
    authenticationApi
      .authorize(code)
      .then((response) => {
        setJwtData(response.data);
        navigate("/");
      })
      .catch((err) => {
        console.error("Error during authentication initialization:", err);
      });
    return <LoaderScreen />;
  }
  if (!storage.current.tenant) return <TenantSelectPage />;
  return <ApplicationLayout />;
};
