import { useCallback, useMemo } from "react";

import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router";

import { useAssetById } from "fetch/asset";
import { useDeviceById } from "fetch/device";
import { useIntegrationById } from "fetch/integration";
import { fetchQueryData } from "fetch/queryUtils";
import { TelemetryEntityType } from "fetch/telemetry";

import { DASHBOARD_VIEW_STATE_PARAM_KEY } from "utils/constants";

import { EntityType } from "globalTypes";

import { store } from "../redux/store";

export interface DashboardState {
  id: string;
  name?: string;
  entityId: string | undefined;
  entityType?: EntityType;
  datasourceType?: TelemetryEntityType;
  datasourceId?: string;
}

export interface DashboardStateParam {
  pushNewView: (viewState: DashboardState) => void;
  popView: (id: string) => void;
  getDecodedState: () => DashboardState[] | undefined;
  getLastView: () => DashboardState | undefined;
  getEncodedState: () => string | undefined;
  isRootView: () => boolean;
}

export const encodeStateParam = (param: DashboardState[]): string => {
  return window.btoa(JSON.stringify(param));
};

export const decodeStateParam = (param: string): DashboardState[] => {
  return JSON.parse(window.atob(param));
};

const prefetchEntity = (
  queryClient: QueryClient,
  entityId: string,
  entityType: EntityType,
) => {
  switch (entityType) {
    case EntityType.ASSET:
      return fetchQueryData(queryClient, useAssetById, {
        pathParams: {
          assetId: entityId,
        },
      });
    case EntityType.DEVICE:
      return fetchQueryData(queryClient, useDeviceById, {
        pathParams: {
          deviceId: entityId,
        },
      });
    case EntityType.INTEGRATION:
      return fetchQueryData(queryClient, useIntegrationById, {
        pathParams: {
          integrationId: entityId,
        },
      });
  }
};

const useDashboardStateParam = (): DashboardStateParam => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const setInitialState = useCallback(
    (viewState: DashboardState) => {
      const dashboardViews = store.getState().dashboard.views;
      const initialView = dashboardViews.find((view) => view.initial);
      if (initialView) {
        const stateArray: DashboardState[] = [
          {
            id: initialView.identifier.id,
            name: initialView.name,
            entityId: undefined,
          },
        ];
        stateArray.push(viewState);
        setSearchParams({
          [DASHBOARD_VIEW_STATE_PARAM_KEY]: encodeStateParam(stateArray),
        });
      }
    },
    [setSearchParams],
  );

  const getDecodedState = useCallback(() => {
    const param = searchParams.get(DASHBOARD_VIEW_STATE_PARAM_KEY);

    if (param) return decodeStateParam(param);
  }, [searchParams]);

  const pushNewView = useCallback(
    (viewState: DashboardState) => {
      if (viewState.entityId && viewState.entityType) {
        prefetchEntity(queryClient, viewState.entityId, viewState.entityType);
      }
      const decodedState = getDecodedState();
      if (decodedState) {
        decodedState.push(viewState);
        setSearchParams({
          [DASHBOARD_VIEW_STATE_PARAM_KEY]: encodeStateParam(decodedState),
        });
      } else setInitialState(viewState);
    },
    [getDecodedState, queryClient, setInitialState, setSearchParams],
  );

  const popView = useCallback(
    (id: string) => {
      const decodedState = getDecodedState();
      if (decodedState) {
        const popIndex = decodedState.findIndex((view) => view.id === id);
        if (popIndex === 0) setSearchParams();
        else {
          decodedState.length = popIndex + 1;
          setSearchParams({
            [DASHBOARD_VIEW_STATE_PARAM_KEY]: encodeStateParam(decodedState),
          });
        }
      }
    },
    [getDecodedState, setSearchParams],
  );

  const getLastView = useCallback(() => {
    const decodedState = getDecodedState();
    if (decodedState) {
      return decodedState.pop();
    } else return undefined;
  }, [getDecodedState]);

  const getEncodedState = useCallback(() => {
    const encodedState = searchParams.get(DASHBOARD_VIEW_STATE_PARAM_KEY);
    return encodedState ?? undefined;
  }, [searchParams]);

  const isRootView = useCallback(() => {
    return getLastView() == null;
  }, [getLastView]);

  return useMemo(() => {
    return {
      pushNewView,
      popView,
      getDecodedState,
      getLastView,
      getEncodedState,
      isRootView,
    };
  }, [
    pushNewView,
    popView,
    getDecodedState,
    getLastView,
    getEncodedState,
    isRootView,
  ]);
};

export default useDashboardStateParam;
