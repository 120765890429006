import useUserPreferencesStore from "hooks/useUserPreferences";

const getPermissions = () => {
  const permissions = useUserPreferencesStore.getState().permissions;
  const activeTenantId =
    useUserPreferencesStore.getState().tenant?.identifier.id;
  if (activeTenantId) return permissions;
  return [];
};

const resourcePermissionCheckVisibility = (componentId: string | string[]) => {
  const permissions = getPermissions();

  if (Array.isArray(componentId)) {
    return componentId.some((id) => {
      const resource = permissions?.find(
        (permission) => permission.resource.name === id,
      );
      return resource?.visible ?? false;
    });
  }

  const resource = permissions?.find(
    (permission) => permission.resource.name === componentId,
  );
  return resource?.visible ?? false;
};

const resourcePermissionCheckActionability = (componentId: string) => {
  const permissions = getPermissions();
  const resource = permissions?.find(
    (permission) => permission.resource.name === componentId,
  );

  if (!resource) return false;

  return resource.actionable;
};

const resourcePermissionCheck = (componentId: string) => {
  const permissions = getPermissions();
  const resource = permissions?.find(
    (permission) => permission.resource.name === componentId,
  );

  if (!resource) return undefined;

  return {
    name: componentId,
    visible: resource.visible,
    actionable: resource.actionable,
  };
};

const multipleResourcePermissionCheck = (componentIds: Array<string>) => {
  const resources = componentIds.map((componentId) =>
    resourcePermissionCheck(componentId),
  );

  if (!resources.length) return undefined;

  return resources;
};

export {
  resourcePermissionCheckVisibility,
  resourcePermissionCheckActionability,
  resourcePermissionCheck,
  multipleResourcePermissionCheck,
  getPermissions,
};
