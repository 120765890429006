import { useCallback, useState } from "react";

import { useNavigate } from "react-router";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  ListItemButton,
  ListItemProps,
  ListItemText,
} from "@mui/material";

import styled from "@emotion/styled";
import { orderBy } from "lodash";
import { darken } from "polished";

import Loader from "components/Loader";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { useProjectSearch } from "fetch/project";

import { ProjectPayloadGet } from "domain/entities/project";

import { SystemData } from "globalTypes";

type SidebarSelectOptions = {
  type: string | undefined;
  systemData: SystemData;
};

type ProjectSelectProps = ListItemProps & {
  open?: boolean;
  activeProject: ProjectPayloadGet | undefined;
};

function SidebarOptionSelect({ type }: Readonly<SidebarSelectOptions>) {
  const storage = useUserPreferencesStore();

  if (type === "tenant") {
    return null;
  }

  return (
    <SidebarSelectComponent>
      <ProjectSelect activeProject={storage.project} />
    </SidebarSelectComponent>
  );
}

export default SidebarOptionSelect;

const ProjectSelect = (props: ProjectSelectProps) => {
  const { activeProject, open: openProp = false } = props;
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const navigate = useNavigate();

  const [open, setOpen] = useState(openProp);

  const storage = useUserPreferencesStore();

  const { data: projects, isLoading } = useProjectSearch(
    {},
    {
      queryParams: {
        entitiesPerPage: recordsPerPage,
        page: 1,
      },
    },
  );

  const areChildProjectsAvailable =
    projects?.payload?.filter(
      (project) => project.identifier.id !== activeProject?.identifier.id,
    )?.length !== 0;

  const handleToggle = () => {
    if (!areChildProjectsAvailable) {
      return;
    }
    setOpen((state) => !state);
  };

  const handleClickAway = () => {
    if (!areChildProjectsAvailable) {
      return;
    }
    setOpen(false);
  };

  const onChildClick = useCallback(
    (projectId: string) => {
      if (!areChildProjectsAvailable) {
        return;
      }
      setOpen(false);
      const project = projects?.payload.find(
        (project: ProjectPayloadGet) => project.identifier.id === projectId,
      );
      if (!project) {
        return;
      }
      storage.setProject(project);
      navigate(`/project/${project.identifier.id}/overview`);
    },
    [areChildProjectsAvailable, navigate, projects?.payload, storage],
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <span>
        <Item onClick={handleToggle}>
          <NavListItemTitle>{activeProject?.name ?? ""}</NavListItemTitle>
          {open ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon disabled={!areChildProjectsAvailable} />
          )}
        </Item>
        <Collapse in={open}>
          {isLoading ? (
            <Loader />
          ) : (
            <Box>
              {orderBy(projects?.payload, ["name"], ["asc"])
                .filter((project) => project.name !== activeProject?.name)
                .map((project) => (
                  <NavListItemChild
                    key={project.identifier.id}
                    onClick={() => {
                      onChildClick(project.identifier.id);
                    }}
                  >
                    {project.name}
                  </NavListItemChild>
                ))}
            </Box>
          )}
          {projects && projects?.numberOfPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                onClick={() => {
                  setRecordsPerPage(recordsPerPage + 10);
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          )}
        </Collapse>
      </span>
    </ClickAwayListener>
  );
};

const SidebarSelectComponent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

type ItemType = {
  onClick?: () => void;
};

const Item = styled(ListItemButton)<ItemType>`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  padding: 0.5rem 28px 0.5rem 0;
`;

type TitleType = {
  onChildClick?: () => void;
};

const NavListItemTitle = styled(ListItemText)<TitleType>`
  margin: 0;
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  padding: ${(props) => props.theme.spacing(0)}
    ${(props) => props.theme.spacing(1)} ${(props) => props.theme.spacing(0)}
    ${(props) => props.theme.spacing(7)};
  opacity: 0.6;
  color: ${(props) => props.theme.sidebar.color};
`;

const NavListItemChild = styled(ListItemText)<TitleType>`
  margin: 0;
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(1)} ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(7)};
  opacity: 0.6;
  color: ${(props) => props.theme.sidebar.color};
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
    cursor: pointer;
  }
`;

type IconExpandType = {
  disabled?: boolean;
};

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => props.theme.sidebar.color};
  opacity: 0.5;
`;

const ExpandMoreIcon = styled(ExpandMore)<IconExpandType>`
  color: ${(props) =>
    props.disabled
      ? darken(0.5, props.theme.sidebar.color)
      : props.theme.sidebar.color};
  opacity: 0.5;
`;
