import {
  DevicePayloadPost,
  DevicePayloadPut,
  DeviceResponse,
  DeviceResponseSearch,
  DeviceReferenceResponseSearch,
  DeviceImportPayloadPost,
} from "domain/entities/device";

import {
  PROJECT_ID_PARAM,
  DEVICE_SPECIFICATION_ID_PARAM,
  TEMPLATE_ID_PARAM,
  FILE_PARAM,
} from "utils/constants";

import {
  Status,
  ReferenceQueryParams,
  ImportResponse,
  ExportRequestBody,
} from "globalTypes";

import axiosClient from "./axios";
import {
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
  SearchParams,
} from "./queryUtils";

export const deviceApi = {
  createDevice: (body: DevicePayloadPost) =>
    axiosClient.post<DeviceResponse>("/hive/device", body),
  claimDevice: (deviceId: string, body: DevicePayloadPut) =>
    axiosClient.put<DeviceResponse>(`/hive/device/claim/${deviceId}`, body),
  updateDeviceById: (deviceId: string, body: DevicePayloadPut) =>
    axiosClient.put<DeviceResponse>(`/hive/device/${deviceId}`, body),
  deleteDeviceById: (deviceId: string) =>
    axiosClient.delete<DeviceResponse>(`/hive/device/${deviceId}`),
  importDeviceViaTemplate: (body: DeviceImportPayloadPost) => {
    const formData = new FormData();

    formData.append(PROJECT_ID_PARAM, body.projectId);
    formData.append(DEVICE_SPECIFICATION_ID_PARAM, body.deviceSpecificationId);
    formData.append(TEMPLATE_ID_PARAM, body.templateId);
    formData.append(FILE_PARAM, body.file);

    return axiosClient.post<DeviceResponse>(
      "/hive/device/import/via/template",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  },
  importDevices: (requestBody: DeviceResponse) =>
    axiosClient.post<ImportResponse>("/hive/device/import", {
      entities: requestBody,
    }),
  exportDevices: (requestBody: ExportRequestBody) =>
    axiosClient.post<DeviceResponseSearch>("/hive/device/export", requestBody),
};

export interface DeviceSearchQueryParams {
  projectId: string;
  deviceSpecificationId?: string;
  includeImage?: boolean;
  name?: string;
  statuses?: Status[];
  type?: string;
  label?: string;
  externalId?: string;
  devices?: string[];
}

export interface DeviceReferenceQueryParams extends ReferenceQueryParams {
  deviceSpecificationId?: string;
}

export const useDeviceSearch = createSearchApiQuery<
  DeviceResponseSearch,
  undefined,
  DeviceSearchQueryParams
>("/hive/device");

export const useDeviceSearchInfinite = createApiQueryInfinite<
  DeviceResponseSearch,
  undefined,
  DeviceSearchQueryParams & SearchParams,
  number
>("/hive/device", {
  getNextPageParam: (lastPage) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useDeviceById = createApiQuery<
  DeviceResponse,
  { deviceId: string }
>("/hive/device/{deviceId}");

export const useDeviceReferenceSearch = createApiQuery<
  DeviceReferenceResponseSearch,
  undefined,
  DeviceReferenceQueryParams
>("/hive/device/reference");
