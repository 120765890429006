import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { default as AlarmsIcon } from "@mui/icons-material/NotificationsNone";
import {
  IconButton,
  Popover as MuiPopover,
  Tooltip,
  Badge,
} from "@mui/material";

import styled from "@emotion/styled";
import throttle from "lodash.throttle";

import AlertList from "components/notifications/AlertList";
import { AlertItemType } from "components/notifications/types";

import { useAlarms } from "hooks/useAlarms";

function NavbarAlarmsDropdown() {
  const {
    alarms,
    newAlarmCount,
    showSeen,
    setShowSeen,
    query,
    unacknowledgedQuery,
  } = useAlarms();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const { t } = useTranslation();

  const listRef = useRef<HTMLUListElement>(null);

  const checkAndFetchNextPage = useMemo(() => {
    return throttle(() => {
      const element = listRef.current;
      if (!element) return;

      const isScrollable = element.scrollHeight > element.clientHeight;
      const isAtBottom =
        element.scrollTop + element.offsetHeight >= element.scrollHeight - 50;

      if (
        (!isScrollable || isAtBottom) &&
        query.hasNextPage &&
        !query.isFetchingNextPage
      ) {
        query.fetchNextPage();
      }
    }, 100);
  }, [query]);

  useEffect(() => {
    const element = listRef.current;
    if (element) {
      element.addEventListener("scroll", checkAndFetchNextPage);
      checkAndFetchNextPage();
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", checkAndFetchNextPage);
      }
      checkAndFetchNextPage.cancel();
    };
  }, [checkAndFetchNextPage]);

  const visibleAlarms = useMemo(() => {
    if (!alarms) return [];
    if (showSeen) return alarms;
    return alarms.slice(0, 5);
  }, [alarms, showSeen]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setShowSeen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("navbar.alarms.tooltip")}>
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Badge badgeContent={newAlarmCount} color="secondary">
            <AlarmsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        sx={{
          maxHeight: "auto",
          ".MuiPaper-root": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <AlertList
          isLoading={query.isLoading}
          data={visibleAlarms}
          newAlertCount={newAlarmCount ?? 0}
          toggleAlertSeen={() => {}}
          isExpanded={showSeen}
          setIsExpanded={setShowSeen}
          listRef={listRef}
          isFetching={query.isFetching || query.isFetchingNextPage}
          alertItemType={AlertItemType.ALARM}
          refetch={query.refetch}
          refetchUnaknowledged={unacknowledgedQuery.refetch}
        />
      </Popover>
    </React.Fragment>
  );
}

export default NavbarAlarmsDropdown;

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;
