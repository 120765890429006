import React from "react";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router";

import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import {
  Drawer as MuiDrawer,
  ListItemButton,
  Box,
  Typography,
} from "@mui/material";

import styled from "@emotion/styled";
import { TFunction } from "i18next";

import { SystemData } from "globalTypes";
import { SidebarItemsType } from "types/sidebar";

import SidebarNav from "./SidebarNav";

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  systemData: SystemData;
  showFooter?: boolean;
  logo?: string;
  alt?: string;
};

type LogoSectionProps = {
  logo?: string;
  alt?: string;
};

type InvalidImageProps = {
  t: TFunction;
};

const InvalidImage = (props: InvalidImageProps) => {
  const { t } = props;

  return (
    <BrandIconFallback>
      <ImageNotSupportedIcon />
      <Typography variant="h6">{t("sidebar.common.noBranIconMsg")}</Typography>
    </BrandIconFallback>
  );
};

const LogoSection = (props: LogoSectionProps) => {
  const { t } = useTranslation();

  if (!props.logo) return <InvalidImage t={t} />;
  return (
    <BrandIcon
      src={props.logo}
      alt={props.alt ?? t("sidebar.common.noBrandIconAlt")}
    />
  );
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  logo,
  alt,
  systemData,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink as any} to="/">
        <LogoSection logo={logo} alt={alt} />
      </Brand>
      <SidebarNav items={items} systemData={systemData} />
    </Drawer>
  );
};

export default Sidebar;

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled.img`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  height: 50px;
`;

const BrandIconFallback = styled(Box)`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;
