import React, { useCallback } from "react";

import { User } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import styled from "@emotion/styled";

import { logout } from "fetch/authentication";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    logout();
  };

  const openUserPage = useCallback(() => {
    navigate(`profile`);
  }, [navigate]);

  return (
    <>
      <Tooltip title={t("navbar.account.tooltip")}>
        <IconButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <User />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={openUserPage}>
          {t("navbar.account.profile")}
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          {t("navbar.account.signout")}
        </MenuItem>
      </Menu>
    </>
  );
}

export default NavbarUserDropdown;
