import { Suspense, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useLocation, useOutletContext } from "react-router";
import useWebSocket from "react-use-websocket";

import { CssBaseline, Paper as MuiPaper, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import styled from "@emotion/styled";

import Content from "components/Content";
import GlobalStyle from "components/GlobalStyle";
import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import getSidebarItems from "components/sidebar/sidebarItems";
import { SkeletonSidebar } from "components/skeletons";

import { usePersistEntity } from "hooks/usePersistEntity";
import { useSaveDashboardState } from "hooks/useSaveDashboardState";
import useUserPreferencesStore from "hooks/useUserPreferences";

import { useProjectSearch } from "fetch/project";
import { useSystem } from "fetch/system";
import { useTenantById, useTenantSearch } from "fetch/tenant";
import { useUserPermissions } from "fetch/user";
import { useUserProfileById } from "fetch/userProfile";
import { WS_CONFIG, createWsUri } from "fetch/websocket/websocket";

import { DRAWER_WIDTH } from "utils/constants";
import { getAccessToken, getDecodedToken } from "utils/jwt";

export interface DashboardContextType {
  isDashboardEditMode: boolean;
  setIsDashboardEditMode: (isEditMode: boolean) => void;
  isFullScreenDashboard: boolean;
  setIsFullScreenDashboard: (isFullscreen: boolean) => void;
}

export function useDashboardOutletContext() {
  return useOutletContext<DashboardContextType>();
}

const Application = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isDashboardEditMode, setIsDashboardEditMode] = useState(false);
  const [isFullScreenDashboard, setIsFullScreenDashboard] = useState(false);

  const saveDashboardState = useSaveDashboardState({
    setIsDashboardEditMode,
  });

  const theme = useTheme();
  const storage = useUserPreferencesStore();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const router = useLocation();
  const { t } = useTranslation();

  const applicationPaddingTop = useMemo(() => {
    if (
      router.pathname.includes("dashboard/") ||
      router.pathname.includes("overview")
    ) {
      return 1;
    } else {
      return isLgUp ? 6 : 3;
    }
  }, [isLgUp, router.pathname]);

  useWebSocket(createWsUri(), {
    ...WS_CONFIG,
    protocols: [getAccessToken() ?? ""],
    share: true,
  });

  const { data: application, error: systemError } = useSystem({
    variables: {},
  });

  const { data: tenants, error: tenantError } = useTenantSearch(
    {},
    {
      queryParams: {},
    },
  );

  const { data: currentTenantData } = useTenantById({
    variables: {
      pathParams: {
        tenantId: storage.tenant?.identifier.id ?? "",
      },
    },
  });

  const {
    data: projects,
    error: projectError,
    refetch: refetchProjects,
  } = useProjectSearch(
    {},
    {
      queryParams: {},
    },
  );

  const { data: user } = useUserProfileById({
    variables: {
      pathParams: {
        userId: getDecodedToken()?.userId ?? "",
      },
    },
    enabled: !!getDecodedToken()?.userId,
  });

  const { data: permissions } = useUserPermissions({
    variables: {
      pathParams: {
        userId: getDecodedToken()?.userId ?? "",
      },
    },
    enabled: !!getDecodedToken()?.userId,
    select: (data) => data.payload,
  });

  usePersistEntity(
    user?.payload,
    storage.setUser,
    storage?.user?.identifier.id,
    false,
    false,
    true,
  );

  usePersistEntity(
    tenants?.payload.find(
      (tenant) => tenant.identifier.id === user?.payload.defaults.tenantId,
    ) ?? tenants?.payload,
    storage.setTenant,
    storage.tenant?.identifier.id,
  );

  const isProjectOfActiveTenant = projects?.payload.some(
    (proj) => proj.identifier.id === storage.project?.identifier.id,
  );

  usePersistEntity(
    projects?.payload.find(
      (project) => project.identifier.id === user?.payload.defaults.projectId,
    ) ?? projects?.payload,
    storage.setProject,
    storage.project?.identifier.id,
    false,
    true,
    !isProjectOfActiveTenant,
  );

  const currentProject = projects?.payload.find(
    (p) => p.identifier.id === storage.project?.identifier.id,
  );

  const defaultTenant = tenants?.payload.find(
    (tenant) => tenant.identifier.id === user?.payload.defaults.tenantId,
  );
  const defaultProject = projects?.payload.find(
    (project) => project.identifier.id === user?.payload.defaults.projectId,
  );

  const logo = useMemo(() => {
    const currentTenant = tenants?.payload.find(
      (t) => t.identifier.id === storage.tenant?.identifier.id,
    );
    if (!currentTenant?.image) return application?.payload.image;

    return currentTenant.image;
  }, [
    application?.payload.image,
    storage.tenant?.identifier.id,
    tenants?.payload,
  ]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const error = systemError ?? tenantError ?? projectError;

  const sidebarItems = getSidebarItems({
    tenant: storage.tenant,
    project: storage.project,
    t,
  });

  useEffect(() => {
    if (permissions?.length) storage.setPermissions(permissions);
    // eslint-disable-next-line
  }, [permissions]);

  useEffect(() => {
    if (storage.isFirstLogin) storage.setIsFirstLogin(false);
    if (!application) return;
    // eslint-disable-next-line
  }, [application]);
  useEffect(() => {
    refetchProjects();
  }, [refetchProjects, storage.tenant]);

  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  const navigateToDefaultRoute = () => {
    if (router.pathname === "/") {
      if (defaultTenant && !defaultProject) {
        return (
          <Navigate to={`tenant/${defaultTenant.identifier.id}/overview`} />
        );
      } else if (defaultTenant && defaultProject) {
        return (
          <Navigate to={`project/${defaultProject?.identifier.id}/overview`} />
        );
      }
    }
  };

  return (
    <Content error={error}>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Suspense fallback={<SkeletonSidebar />}>
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
              <Sidebar
                PaperProps={{ style: { width: DRAWER_WIDTH } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                items={sidebarItems}
                logo={logo}
                alt={application?.payload?.name}
                systemData={{
                  application,
                  projects,
                  tenants,
                }}
              />
            </Box>
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidebar
                PaperProps={{ style: { width: DRAWER_WIDTH } }}
                items={sidebarItems}
                logo={logo}
                systemData={{
                  application,
                  projects,
                  tenants,
                }}
              />
            </Box>
          </Suspense>
        </Drawer>
        <AppContent>
          <Navbar
            onDrawerToggle={handleDrawerToggle}
            languages={currentTenantData?.payload.languages}
            isDashboardEditMode={isDashboardEditMode}
            setIsDashboardEditMode={setIsDashboardEditMode}
            isFullScreenDashboard={isFullScreenDashboard}
            setIsFullScreenDashboard={setIsFullScreenDashboard}
            saveDashboardState={saveDashboardState}
          />
          <MainContent
            p={isLgUp ? 6 : 3}
            paddingTop={applicationPaddingTop}
            sx={{ height: "calc(100vh - 64px)" }}
          >
            {router.pathname === "/" &&
            !defaultProject &&
            !defaultTenant &&
            currentProject ? (
              <Navigate
                to={`project/${currentProject.identifier.id}/overview`}
              />
            ) : (
              navigateToDefaultRoute()
            )}
            <Outlet
              context={{
                isDashboardEditMode,
                setIsDashboardEditMode,
                isFullScreenDashboard,
                setIsFullScreenDashboard,
              }}
            />
          </MainContent>
        </AppContent>
      </Root>
    </Content>
  );
};

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${DRAWER_WIDTH}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
  ${(props) => props.theme.breakpoints.up("lg")} {
    max-width: calc(100% - ${DRAWER_WIDTH}px);
  }
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  overflow-x: auto;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export default Application;
