import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router";

import { dashboardApi } from "fetch/dashboard";
import { dashboardViewApi } from "fetch/dashboardView";
import { useApiMutation } from "fetch/queryUtils";

import { DashboardPayloadPut } from "domain/entities/dashboard";
import { DashboardLayoutWidget } from "domain/entities/dashboardView";

import * as GridLayoutTranslator from "utils/gridLayoutTranslator";

import { store } from "redux/store";

const useSaveDashboardState = ({
  setIsDashboardEditMode,
  overviewDashboardId,
}: {
  setIsDashboardEditMode: (isEditMode: boolean) => void;
  overviewDashboardId?: string;
}) => {
  const { id } = useParams();
  const activeId = overviewDashboardId ?? id;
  const { t } = useTranslation();
  const location = useLocation();
  const mutationUpdate = useApiMutation(
    ({
      dashboardViewId,
      layout,
    }: {
      dashboardViewId: string;
      layout: DashboardLayoutWidget[];
    }) =>
      dashboardViewApi.updateDashboardViewById(dashboardViewId, {
        layout: {
          widgets: layout,
        },
      }),
    t("component.dashboard.view.addSuccessMessage"),
  );

  const mutationUpdateDashboard = useApiMutation(
    (payload: DashboardPayloadPut) =>
      dashboardApi.updateDashboardById(activeId ?? "", payload),
    t("component.dashboard.updateSuccessMessage"),
  );

  return useCallback(() => {
    const dashboard = store.getState().dashboard;
    const layouts = dashboard.viewsLayout;
    layouts.forEach((layoutObj) => {
      const transformedLayout = GridLayoutTranslator.translateToApiLayout(
        layoutObj.layout,
      );
      mutationUpdate.mutateAsync({
        dashboardViewId: layoutObj.viewId,
        layout: transformedLayout,
      });
    });

    if (!location.pathname.includes("/overview")) {
      mutationUpdateDashboard.mutateAsync({
        configuration: { timeframe: dashboard.timeframe },
      } as DashboardPayloadPut);
    }

    setIsDashboardEditMode(false);
  }, [
    mutationUpdate,
    mutationUpdateDashboard,
    setIsDashboardEditMode,
    location.pathname,
  ]);
};

export { useSaveDashboardState };
