import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import {
  Typography,
  Box,
  Button,
  List,
  IconButton,
  Tooltip,
} from "@mui/material";

import styled from "@emotion/styled";

import AlarmCard from "components/alarms";
import Loader from "components/Loader";
import ResourceGuard from "components/resourceGuard/ResourceGuard";
import { SkeletonNotification } from "components/skeletons";

import { useAcknowledgeAlarms, useClearAlarms } from "fetch/alarm";

import { NotificationPayloadGet } from "domain/entities/notification";

import { ActionBox } from "shared/styledComponents/Table";

import { Alarm } from "globalTypes";

import Notification from "./Notification";
import { AlertItemType } from "./types";

const AlertList = ({
  isLoading,
  newAlertCount,
  toggleAlertSeen,
  data,
  isExpanded,
  setIsExpanded,
  isFetching,
  listRef,
  alertItemType = AlertItemType.NOTIFICATION,
  refetch,
  refetchUnaknowledged,
}: {
  isLoading: boolean;
  toggleAlertSeen: (alertId: string) => void;
  isExpanded: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
  data?: NotificationPayloadGet[] | Alarm[];
  newAlertCount?: number;
  isFetching: boolean;
  listRef?: React.RefObject<HTMLUListElement>;
  alertItemType?: AlertItemType;
  refetch?: () => void;
  refetchUnaknowledged?: () => void;
}) => {
  const { t } = useTranslation();
  const [selectedAlert, setSelectedAlert] = useState("");

  const acknowledgeAlarms = useAcknowledgeAlarms();
  const clearAlarms = useClearAlarms();

  const onClickAcknowledgeAll = () => {
    if (!data) return;
    acknowledgeAlarms
      .mutateAsync(data.map((alarm) => alarm.identifier.id))
      .finally(() => {
        refetch && refetch();
        refetchUnaknowledged && refetchUnaknowledged();
      });
  };

  const onClickClearAll = () => {
    if (!data) {
      return;
    }

    clearAlarms
      .mutateAsync(data.map((alarm) => alarm.identifier.id))
      .finally(() => refetch && refetch());
  };

  const handleAlertExpand = (alertId: string) => {
    if (selectedAlert === alertId) {
      setSelectedAlert("");
      return;
    }
    setSelectedAlert(alertId);
  };

  const translationKey =
    alertItemType === AlertItemType.NOTIFICATION ? "notification" : "alarm";

  return (
    <>
      {newAlertCount !== undefined && (
        <AlertHeader p={2}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Typography
              variant="subtitle1"
              color={isLoading ? "GrayText" : "textPrimary"}
            >
              {t(`component.${translationKey}.header`, {
                count: newAlertCount,
              })}
            </Typography>
            {(isLoading || isFetching) && <Loader size={10} />}
          </Box>
          {alertItemType === AlertItemType.ALARM && (
            <ActionBox>
              <ResourceGuard component-id="alarmsPage.alarmsTable.acknowledgeAll">
                <span>
                  <Tooltip title={t("page.alarms.tableActions.acknowledgeAll")}>
                    <IconButton onClick={onClickAcknowledgeAll}>
                      <DoneAllOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </ResourceGuard>

              <ResourceGuard component-id="alarmsPage.alarmsTable.clearAll">
                <span>
                  <Tooltip title={t("page.alarms.tableActions.clearAll")}>
                    <IconButton onClick={onClickClearAll}>
                      <NotificationsOffOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              </ResourceGuard>
            </ActionBox>
          )}
        </AlertHeader>
      )}
      <React.Fragment>
        <List
          disablePadding
          sx={{
            overflowY: "auto",
          }}
          ref={listRef}
        >
          {alertItemType === AlertItemType.NOTIFICATION
            ? data?.map((notification) => (
                <Notification
                  key={notification.identifier.id}
                  notification={notification as NotificationPayloadGet}
                  maxMessageLength={128}
                  expandMessage={() =>
                    handleAlertExpand(notification.identifier.id)
                  }
                  toggleSeen={() => toggleAlertSeen(notification.identifier.id)}
                  isNotificationOpen={
                    selectedAlert === notification.identifier.id
                  }
                />
              ))
            : data?.map((alarm) => (
                <AlarmCard
                  key={alarm.identifier.id}
                  alarm={alarm as Alarm}
                  expandMessage={() => handleAlertExpand(alarm.identifier.id)}
                  toggleSeen={() => toggleAlertSeen(alarm.identifier.id)}
                  isAlarmOpen={selectedAlert === alarm.identifier.id}
                  refetch={refetch}
                  refetchUnaknowledged={refetchUnaknowledged}
                />
              ))}

          {isFetching && <SkeletonNotification />}
        </List>
        {setIsExpanded && (
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded
                ? t(`component.${translationKey}.showLess`)
                : t(`component.${translationKey}.showAll`)}
            </Button>
          </Box>
        )}
      </React.Fragment>
    </>
  );
};

const AlertHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export default AlertList;
